/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */


require('./src/components/layout.css')
require('typeface-cutive');
require('typeface-aleo');
require("prismjs/themes/prism-tomorrow.css")